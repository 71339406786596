<div class="main-container">
    <div class="container-fluid">
        <div class="login pt-3 pb-2">
            <div class="login-section">
                <div class="loginSections d-flex justify-content-center">
                    <div class="me-4 rme-0 rmb-4">
                        <div class="login-form" (click)="redirectToProjects('REFRESH')">
                            <div class="login-logo">
                                <img src="../../../assets/images/refresh-control.svg" alt="">
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-4 text-center">
                                    <h3 class="l-wel">Welcome to</h3>
                                    <img src="../../../assets/images/refresh-control-logo.svg" alt="" class="px-4">
                                    <p class="mb-0 loginIcon">
                                        <mat-icon class="l-arrow-right">arrow_forward</mat-icon>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ms-4 rms-0">
                        <div class="login-form" (click)="redirectToProjects('ZONES')">
                            <div class="login-logo">
                                <img src="../../../assets/images/zones-control.svg" alt="">
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-4 text-center">
                                    <h3 class="l-wel">Welcome to</h3>
                                    <img src="../../../assets/images/refresh-control-logo.svg" alt="" class="px-4">
                                    <p class="mb-0 loginIcon">
                                        <mat-icon class="l-arrow-right">arrow_forward</mat-icon>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>